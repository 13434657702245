






import { Component, Vue } from 'vue-property-decorator'

import EventSubscriptionForm from '@/partials/forms/Events/SubscriptionForm.vue'
import CenteredMedium from '@/layouts/Panels/CenteredMedium66.vue'

@Component({
  components: {
    CenteredMedium,
    EventSubscriptionForm
  }
})
export default class EventSubscriptionCreate extends Vue {

}
